import React, { useState } from 'react';
import {
    Box,
    Typography,
    Tooltip,
    Divider,
    Tabs,
    Tab,
    withStyles,
    AppBar,
    makeStyles,
    useTheme,
    Paper,
    IconButton,
    Grid,
    TablePagination,
    TableCell,
    TableRow,
    TableContainer,
    TableBody,
    TableHead,
    Table,
    Button,
} from '@material-ui/core';
import useControlsStyles from '../../styles/useControlsStyles';
import {
    getControlsTabProps,
    CONTROL_TYPES,
    preProcessThePayload,
} from '../../helper';
import FilterModal, {
    FilteredState,
    FILTER_INPUT_TYPE,
} from '../../../../components/Modals/FilterModal';
import {
    CONTROL_ORDERS_FILTER_CARDS,
    CONTROL_ORDERS_FILTERS,
} from '../../helper';
import useControlTable from '../useControlTable';
import Searchbar from '../../../../components/Searchbar';
import GetAppIcon from '@material-ui/icons/GetApp';
import CachedIcon from '@material-ui/icons/Cached';
import { callPhoneNumber } from '../../../../utils/helper';
import { usePolling } from '../../../../hooks/usePolling';
import clsx from 'clsx';

interface OrdersProps {
    [key: string]: any;
}

const StyledTableCell = withStyles((theme) => ({
    head: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
    },
    body: {
        padding: '8px 8px',
        borderTop: '1px solid #EBEDF8',
        borderBottom: '1px solid #EBEDF8',
        '&:first-child': {
            paddingLeft: '16px',
            borderTopLeftRadius: '4px',
            borderBottomLeftRadius: '4px',
            borderLeft: '1px solid #EBEDF8',
        },
        '&:last-child': {
            paddingRight: '16px',
            borderTopRightRadius: '4px',
            borderBottomRightRadius: '4px',
            borderRight: '1px solid #EBEDF8',
        },
    },
}))(TableCell);
const StyledTableHeadCell = withStyles((theme) => ({
    root: {
        minWidth: 112,
    },
    head: {
        backgroundColor: theme.palette.common.white,
        color: '#525050',
        fontWeight: 700,
        fontSize: 16,
        letterSpacing: '0.144px',
        textTransform: 'capitalize',
        padding: '8px',
        borderBottom: 'none',
        //textAlign: 'center',
    },
    body: {
        fontSize: 14,
    },
}))(TableCell);

const StyledTableRow: any = withStyles((theme) => ({
    root: {
        marginBottom: '8px',
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.common.white,
        },
        border: '1px solid #EBEDF8',
        borderRadius: '4px',
    },
}))(TableRow);

const Orders: React.FC<OrdersProps> = ({
    tabs,
    handleLoading,
    controlType,
    lastUpdated,
    setLastUpdated,
    ...props
}) => {
    //State Values
    const {
        ordersTable,
        getColumnComponentValue,
        prepareFilterOptions,
        filteredValues,
        setFilteredValue,
        getAllStaffInfo,
        getAllOrderInfo,
        orders,
        staff,
        configs,
        dispatchUpdateFilter,
        controlCenter,
    } = useControlTable({
        handleLoading,
        controlType,
        lastUpdated,
        setLastUpdated,
    });
    const previousSelected: any = controlCenter?.filters?.[controlType];
    const [isPolling, setIsPolling] = useState<boolean>(false); // State to control polling
    const [rendered, setRendered] = React.useState(false);
    const [page, setPage] = React.useState(previousSelected?.page ?? 0);
    const [rowsPerPage, setRowsPerPage] = React.useState(
        previousSelected?.rowsPerPage ?? 25,
    );
    const [activeFilterCard, setActiveFilterCard] = useState<any>(
        previousSelected?.activeFilterCard ?? {
            type: 'Total',
            subType: '',
        },
    );
    const [searchVal, setSearchVal] = useState(
        previousSelected?.searchVal ?? '',
    );
    const [listingPayload, setListingPayload] = React.useState(
        !!previousSelected?.listingPayload
            ? previousSelected?.listingPayload
            : {
                  filters: activeFilterCard,
                  pageNo: page + 1,
                  pageSize: rowsPerPage,
                  searchText: searchVal,
                  hubCodes: staff?.hubs?.[0]?.id
                      ? [
                            {
                                label: staff?.hubs?.[0]?.name,
                                value: staff?.hubs?.[0]?.id,
                                storeId:
                                    staff?.hubs?.[0]?.hyperlocalStoreId ?? null,
                            },
                        ]
                      : [],
              },
    );

    //Hooks
    const classes = useControlsStyles();
    const theme = useTheme();

    const prepareFilterCard = (card: any) => {
        switch (card?.type) {
            case CONTROL_ORDERS_FILTERS.LIVE:
                return (
                    <Box>
                        <Typography className={classes.cardHeader}>
                            {card?.name}
                            <Typography
                                component={'span'}
                                className={classes.cardSubHeaderH}
                            >
                                {orders?.[card?.valueMappingKey] ?? '0'}
                            </Typography>
                        </Typography>
                        <Box
                            //mt={'8px'}
                            display={'flex'}
                            flexDirection={'row'}
                            alignItems={'center'}
                            justifyContent={'space-between'}
                        >
                            {card?.subFilters?.map(
                                (subFilter: any, index: number) => {
                                    const isNotFirstAndLast =
                                        index != 0 &&
                                        index != card?.subFilters?.length - 1;
                                    return (
                                        <Box
                                            key={`sub-filter-${index}`}
                                            className={classes.smallValueBox}
                                            display={'flex'}
                                            flexDirection={'column'}
                                            justifyContent={'center'}
                                            alignItems={'center'}
                                            m={
                                                isNotFirstAndLast
                                                    ? '0px 12px'
                                                    : '0px 0px'
                                            }
                                            style={
                                                activeFilterCard?.subType ==
                                                    subFilter?.filterValue
                                                        ?.subType &&
                                                subFilter?.filterValue?.type ==
                                                    activeFilterCard?.type
                                                    ? {
                                                          backgroundColor:
                                                              '#D1D8FF',
                                                      }
                                                    : {}
                                            }
                                            onClick={(e) => {
                                                e?.preventDefault();
                                                e?.stopPropagation();
                                                setActiveFilterCard(
                                                    subFilter?.filterValue,
                                                );
                                                setListingPayload(
                                                    (prev: any) => {
                                                        return {
                                                            ...prev,
                                                            pageNo: 1,
                                                            filters:
                                                                subFilter?.filterValue,
                                                        };
                                                    },
                                                );
                                                setPage(0);
                                            }}
                                        >
                                            <Typography
                                                className={classes.cardHeaderXs}
                                            >
                                                {orders?.[
                                                    subFilter?.valueMappingKey
                                                ] ?? '0'}
                                            </Typography>
                                            <Typography
                                                className={
                                                    classes.cardSubHeaderXs
                                                }
                                            >
                                                {subFilter?.name ?? ''}
                                            </Typography>
                                        </Box>
                                    );
                                },
                            )}
                        </Box>
                    </Box>
                );
            default:
                return (
                    <>
                        <Typography className={classes.cardHeader}>
                            {card?.name ?? ''}
                        </Typography>
                        <Typography className={classes.cardSubHeader}>
                            {orders?.[card?.valueMappingKey] ?? '0'}
                        </Typography>
                    </>
                );
        }
    };
    const handleChangePage = (event?: any, newPage?: any) => {
        setPage(newPage);
        setListingPayload((prev: any) => {
            return {
                ...prev,
                pageNo: newPage + 1,
            };
        });
    };
    const handleChangeRowsPerPage = (event?: any) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
        setListingPayload((prev: any) => {
            return {
                ...prev,
                pageNo: 1,
                pageSize: event?.target?.value,
            };
        });
    };
    React.useEffect(() => {
        setRendered(true);
        setIsPolling(true);
    }, []);
    const fetchOrders = (payload: any) => {
        getAllOrderInfo(preProcessThePayload(payload));
    };
    React.useEffect(() => {
        fetchOrders(listingPayload);
        dispatchUpdateFilter({
            [controlType]: {
                activeFilterCard: activeFilterCard,
                page: page,
                rowsPerPage: rowsPerPage,
                searchVal: searchVal,
                listingPayload: listingPayload,
                filteredValues: filteredValues,
            },
        });
    }, [listingPayload]);
    const handleAction = (type: any, row: any) => {
        if (type == 'more') {
        } else if (type == 'call') {
            if (!!row?.callNumber) {
                //handleClose();
                callPhoneNumber(row?.callNumber);
            }
        }
    };
    React.useEffect(() => {
        if (!!rendered) {
            setPage(0);
            setListingPayload((prev: any) => {
                return {
                    ...prev,
                    pageNo: 1,
                    searchText: searchVal,
                };
            });
        }
    }, [searchVal]);
    // the custom hook for polling
    usePolling(
        async () => {
            fetchOrders(listingPayload);
        },
        configs?.pollingTimeOut ?? 15000,
        isPolling,
        false,
    );
    const rows: any = orders?.lineItems;
    console.log('The filter values', JSON.stringify(filteredValues));
    return (
        <Box>
            <Box className={classes.filterContainer}>
                <Box
                    display={'flex'}
                    flexDirection={'row'}
                    alignItems={'center'}
                    flex={1}
                >
                    <FilterModal
                        disabled={false}
                        filterOptions={prepareFilterOptions()}
                        filteredValues={filteredValues}
                        onClose={null}
                        onRemoveAll={null}
                        onFilterApplied={() => {
                            setPage(0);
                            setListingPayload((prev: any) => {
                                return {
                                    ...prev,
                                    pageNo: 1,
                                    ...filteredValues,
                                };
                            });
                        }}
                    />
                    <FilteredState
                        filterOptions={prepareFilterOptions()}
                        filteredValues={filteredValues}
                        onDelete={(filterKey: any) => {
                            let newFilteredValues = { ...filteredValues };
                            delete newFilteredValues?.[filterKey];
                            let newPayload: any = { ...listingPayload };
                            delete newPayload?.[filterKey];
                            setFilteredValue(newFilteredValues);
                            setPage(0);
                            setListingPayload({
                                pageNo: 1,
                                ...newPayload,
                            });
                        }}
                        count={null}
                        selectInfo={null}
                        selected={null}
                    />
                    {/* {!!lastUpdated?.orders && (
                        <Box ml={'auto'} alignItems={'center'}>
                            <Typography className={classes.filterLastUpdated}>
                                {lastUpdated?.orders}
                            </Typography>
                        </Box>
                    )} */}
                </Box>
                <Box mt={2}>
                    <Grid container spacing={2}>
                        {CONTROL_ORDERS_FILTER_CARDS.map(
                            (value: any, index: number) => (
                                <Grid
                                    key={index}
                                    xs={value?.xs ? value?.xs : true}
                                    style={{
                                        maxWidth: 'fit-content',
                                    }}
                                    item
                                >
                                    <Paper
                                        className={clsx([
                                            classes.filterBoxOrder,
                                            value?.filterDisabled
                                                ? classes.filterBoxDisabled
                                                : {},
                                        ])}
                                        style={
                                            value?.filterValue?.type ==
                                                activeFilterCard?.type &&
                                            !activeFilterCard?.subType
                                                ? {
                                                      backgroundColor:
                                                          '#D1D8FF',
                                                  }
                                                : {}
                                        }
                                        onClick={() => {
                                            if (!value?.filterDisabled) {
                                                setActiveFilterCard(
                                                    value?.filterValue ?? {},
                                                );
                                                setListingPayload(
                                                    (prev: any) => {
                                                        return {
                                                            ...prev,
                                                            pageNo: 1,
                                                            filters:
                                                                value?.filterValue,
                                                        };
                                                    },
                                                );
                                                setPage(0);
                                            }
                                        }}
                                    >
                                        {prepareFilterCard(value)}
                                    </Paper>
                                </Grid>
                            ),
                        )}
                    </Grid>
                </Box>
            </Box>
            <Divider style={{ margin: '12px 0', marginBottom: '16px' }} />
            <Box
                display={'flex'}
                flexDirection={'row'}
                alignItems={'center'}
                justifyContent={'space-between'}
                mb={'8px'}
            >
                <Searchbar
                    placeHolderText="Search by Name, Hub Name, Contact No."
                    // placeHolderStyles={{
                    //     color: '#979797',
                    // }}
                    style={{
                        maxWidth: '350px',
                    }}
                    dataSource={null}
                    backendSearchbar={true}
                    onBackendSearch={(searchText: any) => {
                        setPage(0);
                        setSearchVal(searchText);
                    }}
                    value={searchVal}
                    keys={['name', 'hubName']}
                    onFilter={async (filteredData: any) => {}}
                    onChange={(value: any) => {
                        console.log('blur', value);
                        setSearchVal(value);
                    }}
                />

                <Box>
                    {/* <Tooltip title="Download" placement="top">
                        <Button
                            className={classes.iconBtn}
                            color="primary"
                            onClick={(e) => {}}
                            disabled={false}
                        >
                            {`Download  `}
                            <GetAppIcon
                                    {...{
                                        color: 'primary',
                                        size: 'small',
                                        style: {
                                            color: '#fff',
                                        },
                                    }}
                                />
                        </Button>
                    </Tooltip> */}
                    <Tooltip title="Refresh" placement="top">
                        <Button
                            className={classes.iconBtn}
                            color="primary"
                            onClick={(e) => {
                                fetchOrders(listingPayload);
                            }}
                            disabled={false}
                        >
                            {`Refresh  `}
                            {/* <CachedIcon
                                    {...{
                                        color: 'primary',
                                        size: 'small',
                                        style: {
                                            color: '#fff',
                                        },
                                    }}
                                /> */}
                        </Button>
                    </Tooltip>
                </Box>
            </Box>
            {!!rows?.length ? (
                <TableContainer
                    component={Box}
                    className={classes.tableContainerOrders}
                >
                    <Table stickyHeader aria-label="Orders table">
                        <TableHead>
                            <TableRow>
                                {ordersTable?.map((column: any, index: any) => {
                                    return (
                                        <StyledTableHeadCell
                                            key={`column${index}`}
                                        >
                                            {column?.name}
                                        </StyledTableHeadCell>
                                    );
                                })}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {rows?.map((row: any) => (
                                <>
                                    <StyledTableRow key={row.name}>
                                        {ordersTable?.map(
                                            (column: any, index: any) => {
                                                return (
                                                    <StyledTableCell
                                                        key={`row${index}`}
                                                    >
                                                        {getColumnComponentValue(
                                                            row,
                                                            column,
                                                            handleAction,
                                                        )}
                                                    </StyledTableCell>
                                                );
                                            },
                                        )}
                                    </StyledTableRow>
                                    <Divider
                                        style={{
                                            margin: '4px 0',
                                            backgroundColor: '#fff',
                                        }}
                                    />
                                </>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            ) : (
                <Box
                    display={'flex'}
                    flexDirection={'column'}
                    alignItems={'center'}
                    justifyContent={'center'}
                    mt={3}
                >
                    <Typography
                        component={'p'}
                        style={{
                            color: '#ED0000',
                            fontWeight: 700,
                        }}
                    >
                        No data found. Please retry filters and try again.
                    </Typography>
                </Box>
            )}

            <Box className={classes.paginationContainer}>
                {(!!orders?.totalFilteredCount || !!orders?.totalCount) && (
                    <TablePagination
                        rowsPerPageOptions={
                            orders?.totalFilteredCount > 25 ||
                            orders?.totalCount > 25
                                ? [25, 50, 75, 100]
                                : []
                        }
                        component="div"
                        count={orders?.totalFilteredCount ?? orders?.totalCount}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onChangePage={handleChangePage}
                        onChangeRowsPerPage={handleChangeRowsPerPage}
                    />
                )}
            </Box>
        </Box>
    );
};

export default Orders;
