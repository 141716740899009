import { makeStyles } from '@material-ui/core';
import React from 'react';
const useControlsStyles = makeStyles((theme: any) => ({
    root: {
        backgroundColor: theme.palette.background.paper,
        margin: '0 24px',
    },
    tableContainer: {
        width: '100%',
        height: '100%',
        overflowY: 'auto',
        paddingRight: '8px',
        minHeight: 'calc(100vh - 467px)',
        maxHeight: 'calc(100vh - 467px)',
        '&::-webkit-scrollbar': {
            width: '8px',
        },
        '&::-webkit-scrollbar-track': {
            background: '#f1f1f1',
            borderRadius: '8px',
        },
        '&::-webkit-scrollbar-thumb': {
            background: '#888',
            borderRadius: '8px',
        },
        '&::-webkit-scrollbar-thumb:hover': {
            background: '#555',
        },
    },
    tableContainerAlerts: {
        width: '100%',
        height: '100%',
        overflowY: 'auto',
        paddingRight: '8px',
        minHeight: 'calc(100vh - 459px)',
        maxHeight: 'calc(100vh - 459px)',
        '&::-webkit-scrollbar': {
            width: '8px',
        },
        '&::-webkit-scrollbar-track': {
            background: '#f1f1f1',
            borderRadius: '8px',
        },
        '&::-webkit-scrollbar-thumb': {
            background: '#888',
            borderRadius: '8px',
        },
        '&::-webkit-scrollbar-thumb:hover': {
            background: '#555',
        },
    },
    tableContainerOrders: {
        width: '100%',
        height: '100%',
        overflowY: 'auto',
        overflowX: 'auto',
        paddingRight: '8px',
        minHeight: 'calc(100vh - 459px)',
        maxHeight: 'calc(100vh - 459px)',
        '&::-webkit-scrollbar': {
            width: '8px',
        },
        '&::-webkit-scrollbar-track': {
            background: '#f1f1f1',
            borderRadius: '8px',
        },
        '&::-webkit-scrollbar-thumb': {
            background: '#888',
            borderRadius: '8px',
        },
        '&::-webkit-scrollbar-thumb:hover': {
            background: '#555',
        },
    },
    filterContainer: {
        margin: '16px 0',
    },
    paginationContainer: {},
    filterBox: {
        width: 'auto',
        height: 'auto',
        minWidth: 160,
        minHeight: 112,
        boxShadow: 'none',
        padding: '12px 16px',
        border: '1px solid #EBEDF8',
        borderRadius: '4px',
        backgroundColor: '#F6F7F9',
        cursor: 'pointer',
    },
    filterBoxOrder: {
        width: 'auto',
        height: 'auto',
        minWidth: 160,
        minHeight: 112,
        boxShadow: 'none',
        padding: '12px 16px',
        border: '1px solid #EBEDF8',
        borderRadius: '4px',
        backgroundColor: '#F6F7F9',
        cursor: 'pointer',
    },
    filterBoxDisabled: {
        cursor: 'default !important',
    },
    filterBoxRider: {
        width: 'auto',
        height: 'auto',
        minWidth: 122,
        minHeight: 120,
        boxShadow: 'none',
        padding: '12px 16px',
        border: '1px solid #EBEDF8',
        borderRadius: '4px',
        backgroundColor: '#F6F7F9',
        cursor: 'pointer',
    },
    smallValueBox: {
        padding: '6px 6px',
        borderRadius: '4px',
        backgroundColor: '#fff',
        width: 'auto',
        height: 'auto',
        minWidth: 56,
        minHeight: 56,
    },
    cardSubHeader: {
        fontSize: '22px',
        fontWeight: 700,
        marginTop: '16px',
    },
    cardSubHeaderH: {
        fontSize: '22px',
        fontWeight: 700,
        marginLeft: '24px',
    },
    cardHeader: {
        fontSize: '16px',
        fontWeight: 600,
    },
    cardHeaderXs: {
        fontSize: '16px',
        fontWeight: 600,
        color: ' #14112D',
    },
    cardSubHeaderXs: {
        fontSize: '12px',
        fontWeight: 600,
        color: '#14112D',
    },
    iconBtn: {
        marginLeft: 8,
        border: '1px solid #43419e',
        borderRadius: 8,
        padding: '6px 24px',
        backgroundColor: '#43419e',
        color: '#FFF',
        '&:hover': {
            backgroundColor: '#43419e',
        },
    },
    tableValue: {
        wordBreak: 'break-all',
        fontWeight: 600,
        fontSize: 16,
    },
    tableSubValue: {
        wordBreak: 'break-all',
        fontWeight: 400,
        fontSize: 14,
        marginTop: '6px',
    },
    tableValueBold: {
        wordBreak: 'break-all',
        fontWeight: 700,
        fontSize: 16,
    },
    chip: {
        fontSize: 12,
        width: 'auto',
        color: ' #fff',
        border: '1px solid #fff',
        borderRadius: '8px',
        padding: '2px 12px',
        //margin: '0px 8px',
        textAlign: 'center',
        backgroundColor: 'black',
    },
    chipText: {
        fontSize: 12,
        color: 'inherit',
    },
    active: {
        backgroundColor: 'rgba(76, 175, 80, 0.16) !important',
        border: '1px solid #4caf50 !important',
        color: '#4caf50 !important',
    },
    activeFill: {
        backgroundColor: '#4caf50 !important',
        border: '1px solid #4caf50 !important',
        color: '#fff !important',
    },
    activeOutlined: {
        backgroundColor: '#fff !important',
        border: '1px solid #D6D6D6 !important',
        color: '#4caf50 !important',
    },
    pending: {
        backgroundColor: 'rgba(255, 152, 0, 0.16) !important',
        border: '1px solid #ff9800 !important',
        color: '#ff9800 !important',
    },
    pendingFill: {
        backgroundColor: '#ff9800 !important',
        border: '1px solid #ff9800 !important',
        color: '#fff !important',
    },
    pendingOutlined: {
        backgroundColor: '#fff !important',
        border: '1px solid #D6D6D6 !important',
        color: '#ff9800 !important',
    },
    reject: {
        backgroundColor: 'rgba(244, 67, 54, 0.16) !important',
        border: '1px solid #f44336 !important',
        color: '#f44336 !important',
    },
    inactive: {
        backgroundColor: 'rgba(63, 81, 181, 0.16) !important',
        border: '1px solid #3f51b5 !important',
        color: '#3f51b5 !important',
    },
    none: {
        backgroundColor: '#F4F4F4',
        border: '1px solid #686868',
        color: '#686868',
    },
    none2: {
        backgroundColor: 'rgba(55, 55, 78, 0.16)',
        border: '1px solid #37374E',
        color: '#37374E',
    },
    menu: {
        border: '1px solid #3f51b5',
        color: ' #3f51b5',
        borderRadius: 8,
        padding: '8px',
    },
    box: {
        border: '1px solid #43419E',
        color: '#43419E',
        //borderRadius: 8,
        marginRight: '12px',
        padding: '1px',
    },
    rider: {
        border: '1px solid #000000',
        color: ' #000000',
        //borderRadius: 8,
        //padding: '8px',
        marginRight: '12px',
        padding: '1px',
    },
    location: {
        color: ' #000000',
        borderRadius: 8,
        padding: '8px',
        fontSize: 24,
    },
    mr: {
        marginRight: '8px',
    },
    verDivider: {
        margin: '0px 16px',
        backgroundColor: '#EBEDF8',
        borderRight: '1px dashed #BAC0CD',
    },
    alertIcon: {
        color: '#ED837F',
        fontSize: 16,
        margin: '0px 2px',
    },
    filterLastUpdated: {
        fontSize: '16px',
        fontWeight: 700,
        color: '#4CAF50 !important',
    },
    filterLastTime: {
        marginLeft: '8px',
        fontSize: '16px',
        fontWeight: 600,
        color: '#000 !important',
    },
}));
export default useControlsStyles;
