import * as constants from './../constants';

const defaultState = {};

export default function controlCenterReducer(state = defaultState, action) {
    switch (action.type) {
        case constants.SET_FILTER_UPDATE:
            console.log('state.filters', state.filters);
            return {
                ...state,
                filters: {
                    ...state.filters,
                    ...action.payload,
                },
            };
        default:
            return state;
    }
}
